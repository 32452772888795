:root {
    --rdp-cell-size: 32px;
    --rdp-accent-color: #d9d9d9;
    --rdp-background-color: #d9d9d9;
    --rdp-accent-color-dark: #999999;
    --rdp-background-color-dark: #999999;
    --rdp-outline: 1px solid var(--rdp-accent-color);
    --rdp-outline-selected: 1px solid rgba(0, 0, 0, 0.75);
}

.rdp {
    margin: 0;
    display: inline-block;
    padding: 24px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
}

.rdp-day_today:not(.rdp-day_outside) {
    font-weight: 400;
    border: 1px solid #333;
}
.rdp-caption {
    flex-direction: column;
}

.rdp-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: -32px;
}

.rdp-table {
    border-collapse: separate;
    border-spacing: 10px;
    margin: 0 -10px;
}

.rdp-weeknumber,
.rdp-day {
    border-radius: 8px;
}

.rdp-nav_button {
    padding: 10px;
    background: transparent;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
}

.rdp-nav_button:hover {
    border: 1px solid #999999;
}

.rdp-caption_label {
    font-size: 20px;
    line-height: 28px;
}

.rdp-head_cell {
    font-weight: 500;
    font-size: 13px;
    color: #9C9D9F;
}
