@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'BasisGrotesque';
  src: url('../resources/fonts/BasisGrotesque/BasisGrotesque-Light.woff2') format('woff2'),
    url('../resources/fonts/BasisGrotesque/BasisGrotesque-Light.woff') format('woff'),
    url('../resources/fonts/BasisGrotesque/BasisGrotesque-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'BasisGrotesque';
  src: url('../resources/fonts/BasisGrotesque/BasisGrotesque-Regular.woff2') format('woff2'),
    url('../resources/fonts/BasisGrotesque/BasisGrotesque-Regular.woff') format('woff'),
    url('../resources/fonts/BasisGrotesque/BasisGrotesque-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'BasisGrotesque';
  src: url('../resources/fonts/BasisGrotesque/BasisGrotesque-Medium.woff2') format('woff2'),
    url('../resources/fonts/BasisGrotesque/BasisGrotesque-Medium.woff') format('woff'),
    url('../resources/fonts/BasisGrotesque/BasisGrotesque-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'BasisGrotesque';
  src: url('../resources/fonts/BasisGrotesque/BasisGrotesque-Bold.woff2') format('woff2'),
    url('../resources/fonts/BasisGrotesque/BasisGrotesque-Bold.woff') format('woff'),
    url('../resources/fonts/BasisGrotesque/BasisGrotesque-Bold.ttf') format('truetype');
  font-weight: 700;
}

html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: 'BasisGrotesque', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.bullet-list ul li::before {
  content: '\2022';
  color: #999999;
  font-weight: bold;
  display: inline-block;
  width: 16px;
  margin-left: -16px;
}
